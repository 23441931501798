import { css } from '@emotion/react'
import type { SubscriptionInvoicingAutomaticActionsConfiguration } from '@orus.eu/backend/src/invoicing/stores/invoicing-control-event'
import { CheckboxContainer, spacing, useAsyncCallback } from '@orus.eu/pharaoh'
import type { UseTRPCQueryResult } from '@trpc/react-query/dist/shared'
import { memo, useState, type FunctionComponent } from 'react'
import { trpc, trpcReact } from '../../client'

export const BackofficeSubscriptionInvoicingConfigurationCard: FunctionComponent<{ subscriptionId: string }> = memo(
  function BackofficeSubscriptionInvoicingConfigurationCard({ subscriptionId }) {
    const initialConfiguration = trpcReact.invoices.getSubscriptionInvoicingConfiguration.useQuery(subscriptionId)

    return (
      <div
        css={css`
          margin-top: ${spacing[30]};
          margin-bottom: ${spacing[80]};
          display: flex;
          gap: ${spacing[50]};

          > * {
            flex: 1;
          }
        `}
      >
        <SubscriptionInvoicingConfigurationFlag
          initialConfiguration={initialConfiguration}
          subscriptionId={subscriptionId}
          field="automaticDraftGenerationEnabled"
          label="⚙️ Génération automatique des factures en mode brouillon"
        />
        <SubscriptionInvoicingConfigurationFlag
          initialConfiguration={initialConfiguration}
          subscriptionId={subscriptionId}
          field="automaticInvoiceValidationEnabled"
          label="👍 Validation automatique des factures générées"
        />
        <SubscriptionInvoicingConfigurationFlag
          initialConfiguration={initialConfiguration}
          subscriptionId={subscriptionId}
          field="automaticInvoicePaymentEnabled"
          label="💸 Paiement automatique des factures à échéance"
        />
      </div>
    )
  },
)

type SubscriptionInvoicingConfigurationFlagProps = {
  label: string
  subscriptionId: string
  field: keyof SubscriptionInvoicingAutomaticActionsConfiguration
  initialConfiguration: UseTRPCQueryResult<SubscriptionInvoicingAutomaticActionsConfiguration, unknown>
}

const SubscriptionInvoicingConfigurationFlag: FunctionComponent<SubscriptionInvoicingConfigurationFlagProps> = memo(
  function SubscriptionInvoicingConfigurationFlag({ subscriptionId, label, field, initialConfiguration }) {
    const [checkedByUser, setCheckedByUser] = useState<boolean | undefined>(undefined)
    const [updating, setUpdating] = useState(false)

    const handleCheckedChange = useAsyncCallback(
      async (checked: boolean) => {
        setUpdating(true)
        await trpc.invoices.updateSubscriptionInvoicingConfiguration.mutate({
          subscriptionId,
          update: { [field]: checked },
        })
        setUpdating(false)
        setCheckedByUser(checked)
      },
      [field, subscriptionId],
    )

    const initiallyChecked = initialConfiguration.data ? initialConfiguration.data[field] : undefined

    const checked = checkedByUser ?? initiallyChecked

    const enabled = initialConfiguration.data && !updating

    return (
      <CheckboxContainer disabled={!enabled} checked={!!checked} onChange={handleCheckedChange}>
        {label}
      </CheckboxContainer>
    )
  },
)

type CompactInvoicingConfigurationBlockProps = {
  subscriptionId: string
}

export const BackofficeCompactInvoicingConfigurationBlock: FunctionComponent<CompactInvoicingConfigurationBlockProps> =
  memo(function SubscriptionInvoicingConfigurationFlag({ subscriptionId }) {
    const initialConfiguration = trpcReact.invoices.getSubscriptionInvoicingConfiguration.useQuery(subscriptionId)
    const [checkedByUser, setCheckedByUser] = useState<boolean | undefined>(undefined)
    const [updating, setUpdating] = useState(false)

    const handleCheckedChange = useAsyncCallback(
      async (checked: boolean) => {
        setUpdating(true)
        await trpc.invoices.updateSubscriptionInvoicingConfiguration.mutate({
          subscriptionId,
          update: {
            automaticDraftGenerationEnabled: checked,
            automaticInvoiceValidationEnabled: checked,
            automaticInvoicePaymentEnabled: checked,
          },
        })
        setUpdating(false)
        setCheckedByUser(checked)
      },
      [subscriptionId],
    )

    const initiallyChecked = initialConfiguration.data
      ? initialConfiguration.data.automaticDraftGenerationEnabled ||
        initialConfiguration.data.automaticInvoiceValidationEnabled ||
        initialConfiguration.data.automaticInvoicePaymentEnabled
      : undefined

    const checked = checkedByUser ?? initiallyChecked

    const enabled = initialConfiguration.data && !updating

    return (
      <CheckboxContainer size="small" disabled={!enabled} checked={!!checked} onChange={handleCheckedChange}>
        Facturation automatique
      </CheckboxContainer>
    )
  })
